import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { USER_SERVER } from '../components/Config';
import axios from 'axios';

// createAsyncThunk 생성하기
export const asyncAuth = createAsyncThunk(
  // action 이름
  'user/auth',
  // 처리할 비동기 함수
  async () => {
    // 서버에서 데이터를 불러옴
    const res = await axios.get(`${process.env.REACT_APP_SERVER}${USER_SERVER}/auth`, {
      withCredentials: true,
    });

    // action의 payload 리턴
    return res.data;
  }
);

export const registerUser = createAsyncThunk(
  // action 이름
  'user/register',
  // 처리할 비동기 함수
  async (user) => {
    const res = await axios.post(`${process.env.REACT_APP_SERVER}${USER_SERVER}/register`, user);
    return res.data;
  }
);

export const loginUser = createAsyncThunk(
  // action 이름
  'user/login',
  // 처리할 비동기 함수
  async (user) => {
    const res = await axios.post(`${process.env.REACT_APP_SERVER}${USER_SERVER}/login`, user, {
      withCredentials: true,
    });

    return res.data;
  }
);

export const logoutUser = createAsyncThunk(
  // action 이름
  'user/logout',
  // 처리할 비동기 함수
  async () => {
    const res = await axios.get(`${process.env.REACT_APP_SERVER}${USER_SERVER}/logout`, {
      withCredentials: true,
    });

    return res.data;
  }
);

const initUser = { userData: {}, loginSucces: {} };
export const userSlice = createSlice({
  name: 'user',
  initialState: initUser,
  reducers: {
    updateUserType: (state, action) => {
      state.updateType = action.payload;
    },
    // loginUser: (state, action) => {
    //   axios
    //     .post(`${DEV_SERVER_PATH}${USER_SERVER}/login`, action.payload)
    //     .then((res) => {
    //       state.loginSucces = res.data;
    //     });
    // },
    // registerUser: (state, action) => {
    //   axios.post(`${DEV_SERVER_PATH}${USER_SERVER}/register`, action.payload).then((response) => {
    //     let request = response.data;
    //     state.loginSuccess = request;
    //   });
    // },
    // auth: (state) => {
    //   axios.get(`${DEV_SERVER_PATH}${USER_SERVER}/auth`).then((response) => {
    //     console.log(response.data);
    //     state.userData = response.data;
    //   });
    //   // console.log(request);
    //   // return (state.userData = request);
    // },
    // logoutUser: (state) => {
    //   axios.get(`${DEV_SERVER_PATH}${USER_SERVER}/logout`).then((res) => {
    //     state.userData = res.data;
    //   });
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncAuth.fulfilled, (state, action) => {
      state.userData = action.payload;
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.register = action.payload;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loginSucces = action.payload;
    });
    builder.addCase(logoutUser.fulfilled, (state, action) => {
      state.userData = {};
      state.loginSucces = action.payload;
    });
    // Fetching 대기 상태인 Pending
    // .addCase(asyncAuth.pending, (state, action) => {
    //   state.error = null;
    //   state.isAuth = null;
    // })

    // // Fetching 오류 상태인 rejected
    // .addCase(asyncAuth.rejected, (state, action) => {
    //   state.error = null;
    //   state.isAuth = null;
    // });
  },
});

// export const { logoutUser } = userSlice.actions;
export default userSlice.reducer;
export const { updateUserType } = userSlice.actions;
