import React, { useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppBar,
  Button,
  Box,
  Collapse,
  Toolbar,
  Typography,
  Menu,
  Container,
  MenuItem,
  List,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import headerImg from '../../../assets/img-header.png';
import logoRy2 from '../../../assets/ry2.png';
import { logoutUser } from '../../../redux/user';

const membershipMenu = [
  { label: 'Manage Members', value: 'manageuser' },
  { label: 'Membership registration', value: 'register' },
  // { label: 'Approve Pending Members', value: 'memberapprove' },
];
const settings = ['Profile', 'Logout', 'Change PW'];
// const settings = ['Profile', 'PasswordChange', 'Logout'];

const CustomMenu = styled(Menu)({
  '& .MuiPaper-root': {
    backgroundColor: '#C50000',
    color: 'white',
  },
});

function Header(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElMembership, setAnchorElMembership] = useState(null);
  const [openMembership, setOpenMembership] = useState(false);

  const user = useSelector((state) => state.user);
  const matches = useMediaQuery('(max-width:768px)');

  const pages = user.userData.isAdmin ? ['License', 'History', 'Membership', 'Other', 'Admin'] : ['License', 'History'];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenMembershipMenu = (event) => {
    setAnchorElMembership(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseMembershipMenu = () => {
    setAnchorElMembership(null);
  };
  const handleMenuButton = (name) => {
    let lowerName = name.toLowerCase();

    if (lowerName !== 'membership') {
      navigate('/' + lowerName);
    } else if (lowerName !== 'managemember') {
      navigate('/' + lowerName);
    }
    setAnchorElNav(null);
  };
  const handleSettingButton = (name) => {
    let lowerName = name.toLowerCase().split(' ').join('');

    if (lowerName === 'logout') {
      dispatch(logoutUser(user)).then((res) => {
        if (res.payload.logoutSuccess) {
          navigate('/login');
        } else {
          alert('Log Out Failed');
        }
      });
    } else if (lowerName === 'profile') {
      navigate('/profile');
    } else if (lowerName === 'changepw') {
      navigate('changepw');
    }

    setAnchorElUser(null);
  };

  const handleClickMembership = () => {
    setOpenMembership(!openMembership);
  };

  return (
    <>
      <AppBar
        position="fixed"
        style={{
          backgroundColor: 'white',
          backgroundImage: `url(${headerImg})`,
        }}
      >
        <Toolbar disableGutters sx={{ height: '100px' }}>
          <Button
            onClick={handleOpenUserMenu}
            sx={{
              flexGrow: 0,
              borderRadius: 1,
              backgroundImage: `url(${logoRy2})`,
              backgroundRepeat: `no-repeat`,
              backgroundSize: `70px 70px`,
              '&:hover': {
                bgcolor: 'white',
              },
              width: '70px',
              height: '70px',
              padding: '0px',
              margin: '24px',
            }}
          />
          <div>
            <Typography variant="h6" fontWeight={600} sx={{ marginTop: 2 }}>
              {props.user.userData.name}
            </Typography>
            <Typography variant="subtitle2">{props.user.userData.email}</Typography>
          </div>
          <Box
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            sx={
              matches
                ? { flexGrow: 1, height: '100%', display: 'flex' }
                : { flexGrow: 1, height: '100%', display: 'none' }
            }
          >
            <Button
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{ margin: 1 }}
            >
              Menu
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={matches ? { display: 'block' } : { display: 'none' }}
            >
              <MenuItem
                onClick={() => {
                  handleMenuButton('License');
                }}
                style={{ width: '180px' }}
              >
                <Typography textAlign="center">License</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleMenuButton('History');
                }}
              >
                <Typography textAlign="center">History</Typography>
              </MenuItem>
              {user.userData.isAdmin && (
                <MenuItem
                  onClick={() => {
                    handleClickMembership();
                  }}
                >
                  <Typography textAlign="center">Membership</Typography>
                  {openMembership ? <ExpandLess /> : <ExpandMore />}
                </MenuItem>
              )}

              <Collapse in={openMembership} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <MenuItem
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuButton('manageuser');
                    }}
                  >
                    ManageMembers
                  </MenuItem>
                  <MenuItem
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuButton('register');
                    }}
                  >
                    Registration
                  </MenuItem>
                </List>
              </Collapse>

              {user.userData.isAdmin && (
                <MenuItem
                  onClick={() => {
                    handleMenuButton('Other');
                  }}
                >
                  <Typography textAlign="center">Other</Typography>
                </MenuItem>
              )}
            </Menu>
          </Box>
          <Menu
            // sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            keepMounted
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem
                key={setting}
                onClick={() => {
                  handleSettingButton(setting);
                }}
              >
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
        <Container
          maxWidth="100%"
          sx={
            matches
              ? { height: '48px', backgroundColor: '#C50000', display: 'none' }
              : { height: '48px', backgroundColor: '#C50000', display: 'flex' }
          }
        >
          <Button
            onClick={() => {
              handleMenuButton('License');
            }}
            sx={{
              width: '160px',
              height: '100%',
              color: 'white',
              textTransform: 'none',
            }}
          >
            License
          </Button>
          <Button
            onClick={() => {
              handleMenuButton('History');
            }}
            sx={{
              width: '160px',
              height: '100%',
              color: 'white',
              textTransform: 'none',
            }}
          >
            History
          </Button>
          {user.userData.isAdmin && (
            <Button
              onClick={handleOpenMembershipMenu}
              endIcon={<ArrowDropDownIcon />}
              sx={{
                width: '160px',
                height: '100%',
                color: 'white',
                textTransform: 'none',
              }}
            >
              Membership
            </Button>
          )}
          {user.userData.isAdmin && (
            <Button
              onClick={() => {
                handleMenuButton('Other');
              }}
              sx={{
                width: '160px',
                height: '100%',
                color: 'white',
                textTransform: 'none',
              }}
            >
              Other
            </Button>
          )}
          <CustomMenu
            id="menu-membership"
            anchorEl={anchorElMembership}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElMembership)}
            onClose={handleCloseMembershipMenu}
          >
            {membershipMenu.map((page) => (
              <MenuItem
                key={page.value}
                onClick={() => {
                  handleMenuButton(page.value);
                  handleCloseMembershipMenu();
                }}
              >
                <Typography textAlign="center">{page.label}</Typography>
              </MenuItem>
            ))}
          </CustomMenu>
        </Container>
      </AppBar>

      <Outlet />
    </>
  );
}
export default Header;
