import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styled, createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import { USER_SERVER } from '../../Config';

import { logoutUser, updateUserType } from '../../../redux/user';
import { enqueueSnackbar } from 'notistack';
axios.defaults.withCredentials = true;

const theme = createTheme({
  main: 'rgba(0, 0, 0, 0.6)',
  orange: orange[500],
});

const CustomGrid = styled(Grid)({
  height: '80px',
});

const CustomTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    color: theme.main,
  },
  '& label.Mui-focused': {
    color: theme.orange,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.orange,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.orange,
    },
  },
});

const pwRegExp = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}[^\s]*$/;

// 유효성 체크
const validationSchema = Yup.object().shape({
  password: Yup.string().required('Please enter a password.'),
  newpassword: Yup.string()
    .required('Please enter a new password.')
    .min(8, 'Password must be at least 8 characters')
    .matches(pwRegExp, 'Password should contain at least one digit, one character and one punctuation.'),
  confirmPassword: Yup.string().oneOf([Yup.ref('newpassword'), null], 'New passwords must match'),
});

function ChangePWPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const matches = useMediaQuery('(max-width:768px)');
  const [visiblePW, setVisiblePW] = useState(false);
  const [visibleNewPW, setVisibleNewPW] = useState(false);
  const user = useSelector((state) => state.user);

  const defaultValues = {
    name: user.userData.name,
    role: user.userData.role,
    password: '',
    newpassword: '',
    confirmPassword: '',
  };

  const {
    handleSubmit,
    register,
    watch,
    setFocus,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data, e) => {
    // async request which may result error
    try {
      data._id = user.userData._id;
      data.email = user.userData.email;

      axios.patch(`${process.env.REACT_APP_SERVER}${USER_SERVER}/pw`, data).then((res) => {
        // setUser(res.data);
        if (res.data.userUpdate) {
          //profile 수정 후 logout 처리
          dispatch(logoutUser(user)).then((res) => {
            if (res.payload.logoutSuccess) {
              dispatch(updateUserType('password'));
              navigate('/login');
            }
          });
        } else {
          setError('password', { type: 'custom', message: res.data.message });
          setFocus('password');
        }
      });
    } catch (e) {
      // handle your error
    }
  };

  const gotoLicensePage = () => {
    navigate('/license');
  };

  return (
    <div className="ChangePWPage">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container
          maxWidth="md"
          sx={
            matches ? { paddingTop: '116px', paddingBottom: '100px' } : { paddingTop: '188px', paddingBottom: '100px' }
          }
        >
          <Card elevation={1} sx={{ padding: '16px' }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={3} md={3}>
                <Typography
                  variant="h4"
                  fontWeight={500}
                  sx={{
                    textAlign: 'left',
                    float: 'left',
                    color: 'rgba(197,197,197,1.00)',
                  }}
                >
                  Change Password
                </Typography>
              </Grid>
              <Divider orientation="vertical" flexItem sx={{ marginLeft: '-1px' }} />
              <Grid item xs={12} sm={9} md={9}>
                <Grid item container direction="row" justifyContent="space-around" alignItems="stretch" spacing={3}>
                  <CustomGrid item xs={12}>
                    <CustomTextField
                      fullWidth
                      variant="standard"
                      label="Password"
                      type={visiblePW ? 'text' : 'password'}
                      {...register('password')}
                      value={watch('password') || ''}
                      error={Boolean(errors.password)}
                      helperText={errors.password?.message}
                      size="small"
                      inputProps={{
                        maxLength: 135,
                      }}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() => {
                              setVisiblePW(!visiblePW);
                            }}
                          >
                            {visiblePW && <VisibilityOffIcon />}
                            {!visiblePW && <VisibilityIcon />}
                          </IconButton>
                        ),
                      }}
                    />
                  </CustomGrid>
                  <CustomGrid item xs={12}>
                    <CustomTextField
                      fullWidth
                      variant="standard"
                      label="New Password"
                      type={visibleNewPW ? 'text' : 'password'}
                      {...register('newpassword')}
                      value={watch('newpassword') || ''}
                      error={Boolean(errors.newpassword)}
                      helperText={errors.newpassword?.message}
                      size="small"
                      inputProps={{
                        maxLength: 135,
                      }}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() => {
                              setVisibleNewPW(!visibleNewPW);
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        ),
                      }}
                    />
                  </CustomGrid>
                  <CustomGrid item xs={12}>
                    <CustomTextField
                      fullWidth
                      variant="standard"
                      label="Confirm New Password"
                      type="password"
                      {...register('confirmPassword')}
                      value={watch('confirmPassword') || ''}
                      error={Boolean(errors.confirmPassword)}
                      helperText={errors.confirmPassword?.message}
                      size="small"
                    />
                  </CustomGrid>

                  <Box textAlign="center" sx={{ marginTop: '20px' }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{ textTransform: 'none', marginRight: '32px' }}
                      onClick={gotoLicensePage}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="error"
                      sx={{ textTransform: 'none', marginRight: '32px' }}
                    >
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Container>
      </form>
    </div>
  );
}

export default ChangePWPage;
