import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
// import promiseMiddleware from 'redux-promise';
// import Reducer from '../_reducers';
import Reducer from './user';
import License from './license';
import History from './history';
import Alert from './alert';

const reducers = combineReducers({
  user: Reducer,
  license: License,
  history: History,
  alert: Alert,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer, // 리듀서 들을 정의합니다.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }), // 미들웨어를 정의해주도록 합니다.
  devTools: process.env.NODE_ENV !== 'production', // devTool 의 옵션을 선택합니다.
});
