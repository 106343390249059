/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
// import { auth } from '../_actions/user_actions';
import { useSelector, useDispatch } from 'react-redux';
import { asyncAuth } from '../redux/user';
import { useNavigate } from 'react-router-dom';
// import auth from '../redux/auth';

const Auth = (SpecificComponent, option, adminRoute = null) => {
  function AuthenticationCheck(props) {
    let user = useSelector((state) => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(asyncAuth()).then((res) => {
        //Not Loggined in Status
        if (!res.payload.isAuth) {
          if (option) {
            navigate('/login');
          }
          //Loggined in Status
        } else {
          //supposed to be Admin page, but not admin person wants to go inside
          if (adminRoute && !res.payload.isAdmin) {
            navigate('/');
          }
          //Logged in Status, but Try to go into log in page
          else {
            if (option === false) {
              navigate('/');
            }
          }
        }
      });
    }, []);

    return <SpecificComponent {...props} user={user} />;
  }

  return AuthenticationCheck;
};
export default Auth;

// export default function (SpecificComponent, option, adminRoute = null) {
//   //option : null = anyone, true = login user only, false = logout user only
//   function AuthenticationCheck(props) {
//     const dispatch = useDispatch();

//     const navigate = useNavigate();

//     useEffect(() => {
//       dispatch(auth()).then((response) => {
//         console.log('auth? ', response);

//         if (!response.payload.isAuth) {
//           // login yet
//           if (option) {
//             navigate('/login');
//           }
//         } else {
//           // login
//           if (adminRoute && !response.payload.isAdmin) {
//             navigate('/');
//           } else {
//             if (option === false) {
//               navigate('/');
//             }
//           }
//         }
//       });
//     }, []);

//     return (
//       <SpecificComponent
//         {...props}
//         // user={user}
//       /> // component return이 없으면 React 실행이 안됨.
//     );
//   }

//   return <AuthenticationCheck />;
// }
