import React, { useEffect, useState } from 'react';
// import { registerUser } from '../../../_actions/user_actions';
import { registerUser } from '../../../redux/user';
import { setAlert } from '../../../redux/alert';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styled, createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';

import { GROUP_SERVER } from '../../Config';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
axios.defaults.withCredentials = true;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const theme = createTheme({
  main: 'rgba(0, 0, 0, 0.6)',
  orange: orange[500],
});

const CustomeGrid = styled(Grid)({
  height: '92px',
});
const CustomeGrid2 = styled(Grid)({
  marginTop: '8px',
  height: '92px',
});

const CustomTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    color: theme.main,
  },
  '& label.Mui-focused': {
    color: theme.orange,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.orange,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.orange,
    },
  },
  '& .MuiFormHelperText-root': {
    color: 'red',
    fontSize: '0.8rem',
    fontWeight: '600',
  },
});
const CustomFormControl = styled(FormControl)({
  '& .MuiInputLabel-root': {
    color: theme.main,
  },
  '& label.Mui-focused': {
    color: theme.orange,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.orange,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.orange,
    },
  },
  '& .MuiFormHelperText-root': {
    color: 'red',
    fontSize: '0.8rem',
    fontWeight: '600',
  },
});

const pwRegExp = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}[^\s]*$/;
// const phoneRegExp = /[\\s]^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email.').required(`What's your email address?`),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters.')
    .matches(pwRegExp, 'Password should contain at least one digit, one character and one punctuation.')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match.')
    .required('Confirm Password is required'),
  _group: Yup.string().required('Agency is required'),
  name: Yup.string().max(800).required('Name is required'),
  phonenumber: Yup.string().matches(phoneRegExp, {
    message: 'Phone number is not valid',
    excludeEmptyString: true,
  }),
});
const defaultValues = {
  name: '',
  email: '',
  role: 'user',
  password: '',
  confirmPassword: '',
  _group: '',
  phonenumber: '',
  status: 'approved',
  salt: '',
  DelphiTotalLicenseCount: '',
  DelphiAvailableLicenseCount: '',
};

function RegisterPage(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const matches = useMediaQuery('(max-width:768px)');
  const [visiblePW, setVisiblePW] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const user = useSelector((state) => state.user);

  const handleErrorModalClose = () => {
    setFormErrorMessage('');
    setOpenErrorModal(false);
  };

  const onSubmit = async (data, e) => {
    let formData = {
      name: data.name,
      email: data.email,
      role: data.role,
      password: data.password,
      _group: data._group,
      phonenumber: data.phonenumber,
      status: 'approved',
    };
    // async request which may result error
    try {
      dispatch(registerUser(formData)).then((res) => {
        if (res.payload.success) {
          dispatch(setAlert({ alertType: 'registration', alertMsg: 'Registration Successful' }));
          navigate('/manageuser');
        } else if (res.payload.err) {
          setFormErrorMessage(res.payload.msg);
          setOpenErrorModal(true);
        }
      });
    } catch (e) {
      // handle your error
    }
  };

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (user.userData.isAuth) {
      axios.get(`${process.env.REACT_APP_SERVER}${GROUP_SERVER}/group`).then((res) => {
        // console.log(res.data);
        setGroupList(res.data);
      });
    }
  }, []);

  return (
    <div className="RegisterPage">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container
          maxWidth="md"
          sx={
            matches ? { paddingTop: '116px', paddingBottom: '100px' } : { paddingTop: '188px', paddingBottom: '100px' }
          }
        >
          <Card elevation={1} sx={{ padding: '16px' }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={3} md={3}>
                <Typography
                  variant="h4"
                  fontWeight={500}
                  sx={{
                    textAlign: 'left',
                    float: 'left',
                    color: 'rgba(197,197,197,1.00)',
                  }}
                >
                  Registration
                </Typography>
              </Grid>
              <Divider orientation="vertical" flexItem sx={{ marginLeft: '-1px' }} />
              <Grid item xs={12} sm={9} md={9}>
                <Grid item container direction="row" justifyContent="space-around" alignItems="stretch" spacing={3}>
                  <CustomeGrid item xs={12}>
                    <CustomTextField
                      fullWidth
                      variant="standard"
                      label="ID (Email)"
                      autoComplete="email"
                      {...register('email')}
                      value={watch('email') || ''}
                      // error={Boolean(errors.email)}
                      helperText={errors.email?.message}
                      size="small"
                    />
                  </CustomeGrid>
                  <CustomeGrid item xs={12}>
                    <CustomTextField
                      fullWidth
                      variant="standard"
                      label="Password"
                      type={visiblePW ? 'text' : 'password'}
                      {...register('password')}
                      value={watch('password') || ''}
                      // error={Boolean(errors.password)}
                      helperText={errors.password?.message}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() => {
                              setVisiblePW(!visiblePW);
                            }}
                          >
                            {visiblePW && <VisibilityOffIcon />}
                            {!visiblePW && <VisibilityIcon />}
                          </IconButton>
                        ),
                      }}
                    />
                  </CustomeGrid>
                  <CustomeGrid item xs={12}>
                    <CustomTextField
                      fullWidth
                      variant="standard"
                      label="Confirm Password"
                      type="password"
                      {...register('confirmPassword')}
                      value={watch('confirmPassword') || ''}
                      // error={Boolean(errors.confirmPassword)}
                      helperText={errors.confirmPassword?.message}
                      size="small"
                    />
                  </CustomeGrid>
                  <CustomeGrid2 item xs={12}>
                    <CustomFormControl variant="outlined" fullWidth>
                      <InputLabel shrink>Agency</InputLabel>
                      <Select
                        label="Agency"
                        name="_group"
                        fullWidth
                        {...register('_group')}
                        value={watch('_group') || ''}
                        // error={Boolean(errors._group)}
                        size="small"
                      >
                        {groupList &&
                          groupList.map((group) => {
                            return (
                              <MenuItem key={group._id} value={group._id}>
                                {group.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <FormHelperText style={{ margin: '0px', color: 'red' }}>{errors._group?.message}</FormHelperText>
                    </CustomFormControl>
                  </CustomeGrid2>
                  <CustomeGrid item xs={12}>
                    <CustomTextField
                      fullWidth
                      variant="standard"
                      label="Name"
                      autoComplete="name"
                      {...register('name')}
                      value={watch('name') || ''}
                      inputProps={{
                        maxLength: 800,
                      }}
                      // error={Boolean(errors.name)}
                      helperText={errors.name?.message}
                      size="small"
                    />
                  </CustomeGrid>

                  <CustomeGrid item xs={12}>
                    <CustomTextField
                      fullWidth
                      variant="standard"
                      label="Contact"
                      autoComplete="phonenumber"
                      {...register('phonenumber')}
                      value={watch('phonenumber') || ''}
                      // error={Boolean(errors.phonenumber)}
                      helperText={errors.phonenumber?.message}
                      size="small"
                    />
                  </CustomeGrid>
                  <CustomeGrid2 item xs={12}>
                    <CustomFormControl variant="outlined" size="small" fullWidth>
                      <InputLabel shrink>Rate</InputLabel>
                      <Select label="Rate" name="role" fullWidth defaultValue={'user'} {...register('role')}>
                        <MenuItem value={'user'}>User</MenuItem>
                        <MenuItem value={'admin'}>Admin</MenuItem>
                        <MenuItem value={'super admin'}>Super Admin</MenuItem>
                      </Select>
                    </CustomFormControl>
                  </CustomeGrid2>
                  <Box textAlign="center" sx={{ marginTop: '20px' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="error"
                      sx={{ textTransform: 'none', marginRight: '32px' }}
                    >
                      Register
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{ textTransform: 'none' }}
                      onClick={() => {
                        navigate('/license');
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Container>
      </form>
      <Modal
        open={openErrorModal}
        onClose={handleErrorModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ErrorOutlineRoundedIcon color="warning" style={{ fontSize: 64 }} />
          <Typography id="modal-modal-title" style={{ margin: 16, fontWeight: 600, fontSize: '24px' }}>
            {formErrorMessage}
          </Typography>

          <Button variant="contained" color="error" size="small" onClick={handleErrorModalClose}>
            OK
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default RegisterPage;
