import { createSlice, current } from '@reduxjs/toolkit';
import { vsObject } from '../utils/util';

const initLicense = { licenses: [] };
export const licenseSlice = createSlice({
  name: 'license',
  initialState: initLicense,
  reducers: {
    addLicense: (state, action) => {
      // license 발급정보 추가 중복 check
      let duplicate = false;
      // console.log(current(state.licenses));
      for (let idx in current(state.licenses)) {
        if (vsObject(current(state.licenses)[idx], action.payload)) {
          duplicate = true;
        }
      }
      if (duplicate === false) {
        state.licenses.push(action.payload);
      }
    },
    resetLicense: (state, action) => {
      state.licenses = [];
    },
  },
});

// export const { logoutUser } = userSlice.actions;
export default licenseSlice.reducer;
export const { addLicense, resetLicense } = licenseSlice.actions;
