import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetAlert } from '../../../redux/alert';
import {
  Box,
  Button,
  FormControl,
  Grid,
  Modal,
  InputLabel,
  NativeSelect,
  TextField,
  Typography,
  // useMediaQuery,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { styled, createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import ManageMemberSubComponent from './ManageMemberSubComponent';
import { USER_SERVER, GROUP_SERVER } from './../../Config';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
axios.defaults.withCredentials = true;

const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 460,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const CustomGrid = styled(Grid)({
  minHeight: '64px',
});

const theme = createTheme({
  main: 'rgba(0, 0, 0, 0.6)',
  orange: orange[500],
});

const CustomFormControl = styled(FormControl)({
  '& .MuiInputLabel-root': {
    color: theme.main,
  },
  '& label.Mui-focused': {
    color: theme.orange,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.orange,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.orange,
    },
  },
});

const CustomTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    color: theme.main,
  },
  '& label.Mui-focused': {
    color: theme.orange,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.orange,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.orange,
    },
  },
});

const defaultValues = {
  agency: 'All',
  email: '',
  name: '',
};
const validationSchema = Yup.object({
  email: Yup.string('Enter your email'),
  name: Yup.string('Enter your password'),
});

function ManageMemberPage(props) {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [groupList, setGroupList] = useState([]);
  const [userList, setUserList] = useState([]);
  const user = useSelector((state) => state.user);
  const alert = useSelector((state) => state.alert);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    dispatch(resetAlert());
    setOpen(false);
  };

  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data, e) => {
    // async request which may result error
    try {
      // 조회 조건 param으로 request 요청
      await axios.post(`${process.env.REACT_APP_SERVER}${USER_SERVER}/searchuser`, data).then((res) => {
        // console.log(res);
        setUserList(res.data);
      });
    } catch (e) {
      // handle your error
    }
  };

  useEffect(() => {
    if (user.userData.isAuth) {
      axios.get(`${process.env.REACT_APP_SERVER}${USER_SERVER}/user`).then((res) => {
        setUserList(res.data);
      });
      axios.get(`${process.env.REACT_APP_SERVER}${GROUP_SERVER}/group`).then((res) => {
        // console.log(res.data);
        setGroupList(res.data);
      });
    }
  }, []);

  // user manage modal 닫힐때 현재 search 조건으로 재검색하여 user List update
  const userCahngeCheck = async (state) => {
    if (state) {
      let formData = {
        agency: getValues('agency'),
        email: getValues('email'),
        name: getValues('name'),
      };
      await axios.post(`${process.env.REACT_APP_SERVER}${USER_SERVER}/searchuser`, formData).then((res) => {
        // console.log(res);
        setUserList(res.data);
      });
    }
  };

  useEffect(() => {
    if (alert.alertType === 'registration') {
      setOpen(true);
    }
  }, [alert]);

  return (
    <div className="ManageMemberPage">
      <Grid container sx={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            height: '100%',
            padding: '16px ',
            backgroundColor: 'white',
          }}
        >
          <Grid item container direction="row" justifyContent="center" alignItems="center">
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%', height: '100%' }}>
              <CustomGrid item xs={12}>
                <Typography variant="h3" fontSize={24} fontWeight={600} margin={1}>
                  Search Member
                </Typography>
              </CustomGrid>
              <CustomGrid item xs={12}>
                <CustomFormControl type="text" variant="standard" fullWidth sx={{ height: '40px' }}>
                  <InputLabel shrink htmlFor="agency">
                    Agency
                  </InputLabel>
                  <NativeSelect id="agency" fullWidth size="small" {...register('agency')}>
                    <option value={'All'}>Select a Agency...</option>
                    {groupList &&
                      groupList.map((group) => {
                        return (
                          <option key={group._id} value={group._id}>
                            {group.name}
                          </option>
                        );
                      })}
                  </NativeSelect>
                </CustomFormControl>
              </CustomGrid>
              <CustomGrid item xs={12}>
                <CustomTextField
                  fullWidth
                  variant="standard"
                  label="ID (Email)"
                  size="small"
                  autoComplete="email"
                  sx={{ color: 'red' }}
                  inputProps={{
                    maxLength: 135,
                  }}
                  error={Boolean(errors['email'])}
                  helperText={Boolean(errors['email']) ? "Doesn't look like a valid email" : ''}
                  {...register('email')}
                />
              </CustomGrid>
              <CustomGrid item xs={12}>
                <CustomTextField
                  fullWidth
                  variant="standard"
                  label="Name"
                  size="small"
                  autoComplete="name"
                  sx={{ color: 'red' }}
                  inputProps={{
                    maxLength: 135,
                  }}
                  {...register('name')}
                  error={Boolean(errors['name'])}
                />
              </CustomGrid>

              <Grid item xs={12} sx={{ textAlign: 'center', height: '100%' }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="error"
                  startIcon={<SearchIcon />}
                  sx={{ width: '80%', textTransform: 'none' }}
                >
                  Search
                </Button>
              </Grid>
            </form>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={9}
          sx={{
            height: '100%',
            padding: '16px 16px 0px 16px',
            // display: 'inline-flex',
            // flexDirection: 'column',
            overflow: 'hidden',
            overflowY: 'scroll',
          }}
          container
          spacing={3}
        >
          {userList &&
            userList.map((user) => {
              return (
                <Grid item xs={12} md={4} key={user._id}>
                  <ManageMemberSubComponent
                    userId={user._id}
                    name={user.name}
                    email={user.email}
                    groupname={user.groupname}
                    phonenumber={user.phonenumber}
                    userCahngeCheck={userCahngeCheck}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <CheckCircleOutlineRoundedIcon color="success" style={{ fontSize: 64 }} />
          <Typography id="modal-modal-title" style={{ margin: 16, fontWeight: 600, fontSize: '28px' }}>
            {alert.alertMsg}
          </Typography>
          <Button variant="contained" color="error" size="small" onClick={handleClose}>
            OK
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default ManageMemberPage;
