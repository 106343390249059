import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  // useMediaQuery,
} from '@mui/material';
import { styled, createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { USER_SERVER, GROUP_SERVER } from '../../Config';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { enqueueSnackbar } from 'notistack';

import * as Yup from 'yup';
import axios from 'axios';
axios.defaults.withCredentials = true;

const theme = createTheme({
  main: 'rgba(0, 0, 0, 0.6)',
  orange: orange[500],
});

const CustomGrid = styled(Grid)({
  height: '80px',
});

const CustomTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    color: theme.main,
  },
  '& label.Mui-focused': {
    color: theme.orange,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.orange,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.orange,
    },
  },
});
const CustomFormControl = styled(FormControl)({
  '& .MuiInputLabel-root': {
    color: theme.main,
  },
  '& label.Mui-focused': {
    color: theme.orange,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.orange,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.orange,
    },
  },
});

const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  phonenumber: Yup.string().matches(phoneRegExp, {
    message: 'Phone number is not valid',
    excludeEmptyString: true,
  }),
});

export default function ManageMemberDialog(props) {
  const [groupList, setGroupList] = useState([]);

  const onSubmit = async (data, e) => {
    // async request which may result error
    try {
      axios.patch(`${process.env.REACT_APP_SERVER}${USER_SERVER}/manageuser`, data).then((res) => {
        props.handleClose(e);
        enqueueSnackbar('Member Update Completed.', {
          variant: 'success',
          autoHideDuration: 2500,
        });
      });
    } catch (e) {
      // handle your error
    }
  };

  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      _id: '',
      email: '',
      name: '',
      _group: '',
      role: '',
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (props.open) {
      axios
        .get(`${process.env.REACT_APP_SERVER}${GROUP_SERVER}/group`)
        .then((res) => {
          // console.log(res.data);
          setGroupList(res.data);
        })
        .then(() => {
          axios.get(`${process.env.REACT_APP_SERVER}${USER_SERVER}/manageuser/${props.userId}`).then((res) => {
            reset(res.data);
            // console.log(res.data);
          });
        });
    }
  }, [props]);

  return (
    <Dialog component="form" open={props.open} onClose={props.handleClose} onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle variant="h5">Manage User</DialogTitle>
      <DialogContent>
        <Grid item container direction="row" justifyContent="end" alignItems="flex-end" spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              disabled
              fullWidth
              variant="filled"
              label="ID (Email)"
              size="small"
              autoComplete="email"
              {...register('email')}
              value={watch('email') || ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomFormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="role-label">Rate</InputLabel>
              <Select
                labelId="role-label"
                id="role"
                fullWidth
                label="Rate"
                // disabled={!user.userData.isAdmin}
                {...register('role')}
                value={watch('role') || ''}
              >
                <MenuItem value={'user'}>User</MenuItem>
                <MenuItem value={'admin'}>Admin</MenuItem>
                <MenuItem value={'super admin'}>Super Admin</MenuItem>
              </Select>
            </CustomFormControl>
          </Grid>
          <CustomGrid item xs={12} sm={6}>
            <CustomTextField
              // required
              fullWidth
              variant="standard"
              label="Name"
              autoComplete="name"
              {...register('name')}
              value={watch('name') || ''}
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
              size="small"
            />
          </CustomGrid>

          <CustomGrid item xs={12} sm={6}>
            <CustomTextField
              // required
              fullWidth
              variant="standard"
              label="Contact"
              name="phonenumber"
              {...register('phonenumber')}
              value={watch('phonenumber') || ''}
              error={Boolean(errors.phonenumber)}
              helperText={errors.phonenumber?.message}
              size="small"
            />
          </CustomGrid>
          <CustomGrid item xs={12}>
            <CustomFormControl variant="outlined" fullWidth>
              <InputLabel shrink>Agency</InputLabel>
              <Select
                // disabled={!user.userData.isAdmin}
                label="Agency"
                name="_group"
                fullWidth
                size="small"
                {...register('_group')}
                value={watch('_group') || ''}
              >
                {groupList &&
                  groupList.map((group) => {
                    return (
                      <MenuItem key={group._id} value={group._id}>
                        {group.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </CustomFormControl>
          </CustomGrid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button size="small" variant="outlined" onClick={props.handleClose} sx={{ textTransform: 'none' }}>
          Cancel
        </Button>
        <Button
          // onClick={handleSubmit(onSubmit)}
          type="submit"
          size="small"
          variant="contained"
          color="error"
          sx={{ textTransform: 'none' }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
