export function removeProperty(obj, propertyName) {
  let { [propertyName]: _, ...result } = obj;
  return result;
}

export function downloadLicenseFile(data) {
  let filename = 'Rayence License Key.txt';

  let blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    let link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      let url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function vsObject(obj1, obj2) {
  // obj1, obj2 비교
  if (obj1 !== obj2) {
    const keys1 = Object.keys(obj1); // obj1 객체의 속성들을 담은 배열
    const keys2 = Object.keys(obj2); // obj2 객체의 속성들을 담은 배열

    // 두 keys 배열의 길이가 다르면
    if (keys1.length !== keys2.length) {
      // 두 객체의 속성이 일치하지 않기 때문
      //   console.log(`${obj1}과 ${obj2}는 다른 내용입니다.`);
      return false;
    }

    // keys 배열 중 하나의 요소 순회
    for (let key of keys1) {
      // obj1과 obj2의 key 속성값이 다르면
      if (obj1[key] !== obj2[key]) {
        // obj1과 obj2의 속성값이 일치하지 않기 때문
        // console.log(`${obj1}과 ${obj2}는 다른 내용입니다.`);
        return false;
      }
    }

    // (keys 요소 순회 종료 후)
    // obj1과 obj2의 모든 속성과 속성값이 일치하기 때문
    // console.log(`${obj1}과 ${obj2}는 같은 내용입니다.`);
    return true;
  }
}
