import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import Auth from '../hoc/auth.js';
// pages for this product
import LicensePage from './views/License/LicensePage.js';
import LoginPage from './views/User/LoginPage.js';
import ProfilePage from './views/User/ProfilePage.js';
import ChangePWPage from './views/User/ChangePWPage.js';
import ManageMemberPage from './views/Membership/ManageMemberPage.js';
import RegisterPage from './views/Membership/RegisterPage.js';
import HistoryPage from './views/History/HistoryPage.js';
import OtherPage from './views/Other/OtherPage.js';
import Header from './views/Header/Header.js';
// import Footer from './views/Footer/Footer';

//null   Anyone Can go inside
//true   only logged in user can go inside
//false  logged in user can't go inside

const AuthHeader = Auth(Header, true);
const AuthLoginPage = Auth(LoginPage, false);
const AuthProfilePage = Auth(ProfilePage, true);
const AuthManageMemberPage = Auth(ManageMemberPage, true, true);
const AuthRegisterPage = Auth(RegisterPage, true, true);
const AuthLicensePage = Auth(LicensePage, true);
const AuthHistoryPage = Auth(HistoryPage, true);
const AuthOtherPage = Auth(OtherPage, true, true);
const AuthChangePWPage = Auth(ChangePWPage, true);

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        // TransitionComponent={Slide}
      >
        <Routes>
          <Route element={<AuthHeader />}>
            <Route path="/" element={<AuthHeader />} />
            <Route path="/license" element={<AuthLicensePage />} />
            <Route path="/register" element={<AuthRegisterPage />} />
            <Route path="/profile" element={<AuthProfilePage />} />
            <Route path="/changepw" element={<AuthChangePWPage />} />
            <Route path="/manageuser" element={<AuthManageMemberPage />} />
            <Route path="/history" element={<AuthHistoryPage />} />
            <Route path="/other" element={<AuthOtherPage />} />
          </Route>
          <Route path="/login" element={<AuthLoginPage />} />
        </Routes>

        {/* <Footer /> */}
      </SnackbarProvider>
    </Suspense>
  );
}

export default App;
