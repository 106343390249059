import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styled, createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import { USER_SERVER, GROUP_SERVER } from '../../Config';

import { logoutUser, updateUserType } from '../../../redux/user';

axios.defaults.withCredentials = true;

const theme = createTheme({
  main: 'rgba(0, 0, 0, 0.6)',
  orange: orange[500],
});

const CustomGrid = styled(Grid)({
  height: '80px',
});

const CustomTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    color: theme.main,
  },
  '& label.Mui-focused': {
    color: theme.orange,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.orange,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.orange,
    },
  },
});
const CustomFormControl = styled(FormControl)({
  '& .MuiInputLabel-root': {
    color: theme.main,
  },
  '& label.Mui-focused': {
    color: theme.orange,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.orange,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.orange,
    },
  },
});

const pwRegExp = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}[^\s]*$/;
const phoneRegExp = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;

// 유효성 체크
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Please enter a password.')
    .min(8, 'Password must be at least 8 characters.')
    .matches(pwRegExp, 'Password should contain at least one digit, one character and one punctuation.'),
  // newpassword: Yup.string().nullable().notRequired().min(6, 'Password must be at least 6 characters'),
  confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
  name: Yup.string().max(800).required('Name is required'),
  phonenumber: Yup.string().matches(phoneRegExp, {
    message: 'Phone number is not valid',
    excludeEmptyString: true,
  }),
  // role: Yup.string().required('Rate is required'),
});

function ProfilePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const matches = useMediaQuery('(max-width:768px)');
  const [visiblePW, setVisiblePW] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const user = useSelector((state) => state.user);

  const defaultValues = {
    name: user.userData.name,
    // email: '',
    role: user.userData.role,
    password: '',
    // newpassword: '',
    confirmPassword: '',
    _group: '',
    phonenumber: '',
    status: 'approved',
    // salt: '',
    // DelphiTotalLicenseCount: '',
    // DelphiAvailableLicenseCount: '',
  };

  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data, e) => {
    // async request which may result error
    try {
      // console.log(data);

      axios.patch(`${process.env.REACT_APP_SERVER}${USER_SERVER}/profile`, data).then((res) => {
        // setUser(res.data);
        if (res.data.userUpdate) {
          //profile 수정 후 logout 처리
          dispatch(logoutUser(user)).then((res) => {
            if (res.payload.logoutSuccess) {
              dispatch(updateUserType('profile'));
              navigate('/login');
            }
          });
        } else {
          enqueueSnackbar(res.data.message, {
            variant: 'warning',
            autoHideDuration: 2500,
          });
        }
      });
    } catch (e) {
      // handle your error
    }
  };

  const gotoLicensePage = () => {
    navigate('/license');
  };

  useEffect(() => {
    if (user.userData.isAuth) {
      if (Object.keys(user.userData).length !== 0) {
        axios
          .get(`${process.env.REACT_APP_SERVER}${GROUP_SERVER}/group`)
          .then((res) => {
            // console.log(res.data);
            setGroupList(res.data);
          })
          .then(() => {
            axios.get(`${process.env.REACT_APP_SERVER}${USER_SERVER}/manageuser/${user.userData._id}`).then((res) => {
              reset(res.data);
              // console.log(res.data);
            });
          });
      }
    }
  }, []);

  return (
    <div className="ProfilePage">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container
          maxWidth="md"
          sx={
            matches ? { paddingTop: '116px', paddingBottom: '100px' } : { paddingTop: '188px', paddingBottom: '100px' }
          }
        >
          <Card elevation={1} sx={{ padding: '16px' }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={3} md={3}>
                <Typography
                  variant="h4"
                  fontWeight={500}
                  sx={{
                    textAlign: 'left',
                    float: 'left',
                    color: 'rgba(197,197,197,1.00)',
                  }}
                >
                  Profile
                </Typography>
              </Grid>
              <Divider orientation="vertical" flexItem sx={{ marginLeft: '-1px' }} />
              <Grid item xs={12} sm={9} md={9}>
                <Grid item container direction="row" justifyContent="space-around" alignItems="stretch" spacing={3}>
                  <CustomGrid item xs={12}>
                    <CustomTextField
                      disabled
                      fullWidth
                      variant="filled"
                      label="ID (Email)"
                      value={user.userData.email || ''}
                      size="small"
                    />
                  </CustomGrid>
                  <CustomGrid item xs={12}>
                    <CustomTextField
                      fullWidth
                      variant="standard"
                      label="Password"
                      type={visiblePW ? 'text' : 'password'}
                      {...register('password')}
                      value={watch('password') || ''}
                      error={Boolean(errors.password)}
                      helperText={errors.password?.message}
                      size="small"
                      inputProps={{
                        maxLength: 135,
                      }}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() => {
                              setVisiblePW(!visiblePW);
                            }}
                          >
                            {visiblePW && <VisibilityOffIcon />}
                            {!visiblePW && <VisibilityIcon />}
                          </IconButton>
                        ),
                      }}
                    />
                  </CustomGrid>
                  {/* <CustomGrid item xs={12}>
                    <CustomTextField
                      fullWidth
                      variant="standard"
                      label="New Password"
                      type={visiblePW ? 'text' : 'password'}
                      {...register('newpassword')}
                      value={watch('newpassword') || ''}
                      error={Boolean(errors.newpassword)}
                      helperText={errors.newpassword?.message}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() => {
                              setVisiblePW(!visiblePW);
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        ),
                      }}
                    />
                  </CustomGrid> */}
                  <CustomGrid item xs={12}>
                    <CustomTextField
                      fullWidth
                      variant="standard"
                      label="Confirm Password"
                      type="password"
                      {...register('confirmPassword')}
                      value={watch('confirmPassword') || ''}
                      error={Boolean(errors.confirmPassword)}
                      helperText={errors.confirmPassword?.message}
                      size="small"
                      inputProps={{
                        maxLength: 135,
                      }}
                    />
                  </CustomGrid>
                  <CustomGrid item xs={12}>
                    <CustomFormControl variant="outlined" fullWidth>
                      <InputLabel shrink>Agency</InputLabel>
                      <Select
                        disabled={!user.userData.isAdmin}
                        label="Agency"
                        name="_group"
                        fullWidth
                        size="small"
                        {...register('_group')}
                        value={watch('_group') || ''}
                      >
                        {groupList &&
                          groupList.map((group) => {
                            return (
                              <MenuItem key={group._id} value={group._id}>
                                {group.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </CustomFormControl>
                  </CustomGrid>
                  <CustomGrid item xs={12}>
                    <CustomTextField
                      required
                      fullWidth
                      variant="standard"
                      label="Name"
                      name="name"
                      autoComplete="name"
                      {...register('name')}
                      value={watch('name') || ''}
                      error={Boolean(errors.name)}
                      helperText={errors.name?.message}
                      size="small"
                      inputProps={{
                        maxLength: 800,
                      }}
                    />
                  </CustomGrid>

                  <CustomGrid item xs={12}>
                    <CustomTextField
                      // required
                      fullWidth
                      variant="standard"
                      label="Contact"
                      name="phonenumber"
                      {...register('phonenumber')}
                      value={watch('phonenumber') || ''}
                      error={Boolean(errors.phonenumber)}
                      helperText={errors.phonenumber?.message}
                      size="small"
                    />
                  </CustomGrid>
                  <CustomGrid item xs={12}>
                    <CustomFormControl variant="outlined" size="small" fullWidth>
                      <InputLabel shrink>Rate</InputLabel>
                      <Select
                        label="Rate"
                        name="role"
                        fullWidth
                        disabled={!user.userData.isAdmin}
                        {...register('role')}
                        defaultValue={user.userData.role || ''}
                        value={watch('role') || ''}
                      >
                        <MenuItem value={'user'}>User</MenuItem>
                        <MenuItem value={'admin'}>Admin</MenuItem>
                        <MenuItem value={'super admin'}>Super Admin</MenuItem>
                      </Select>
                    </CustomFormControl>
                  </CustomGrid>
                  <Box textAlign="center" sx={{ marginTop: '20px' }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{ textTransform: 'none', marginRight: '32px' }}
                      onClick={gotoLicensePage}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="error"
                      sx={{ textTransform: 'none', marginRight: '32px' }}
                    >
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Container>
      </form>
    </div>
  );
}

export default ProfilePage;
