import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Card, Grid, Typography } from '@mui/material';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded';
import PersonIcon from '@mui/icons-material/Person';
import InfoIcon from '@mui/icons-material/Info';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { styled } from '@mui/material/styles';
import { setHistory } from '../../../redux/history';
import moment from 'moment';

const CustomCard = styled(Card)({
  padding: '12px',
  marginBottom: 16,
  cursor: 'pointer',
  verticalAlign: 'middle',
  borderRight: 'thick solid transparent',
  '&:hover': {
    borderRight: 'thick orange solid ',
  },
});

const HistorySubCompoent = (props) => {
  const dispatch = useDispatch();

  return (
    <Grid container>
      <Grid item xs={12}>
        <CustomCard
          onClick={(e) => {
            e.stopPropagation();

            dispatch(setHistory({ idx: props.idx, historyData: props.history }));
          }}
        >
          <Box>
            <Typography variant="h5" fontWeight={600}>
              {props.history._customer}
            </Typography>
            <Typography variant="subtitle1" sx={{ margin: '12px' }}>
              {props.history._group ? props.history._group.name : ''}
            </Typography>
            <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={1}>
              <Grid item>
                <GridViewRoundedIcon />
              </Grid>
              <Grid item>
                {props.history._product && <Typography variant="subtitle2">{props.history._product.name}</Typography>}
              </Grid>
            </Grid>

            {props.history.optionLicenses.length > 0 && (
              <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={1}>
                <Grid item>
                  <ViewListRoundedIcon />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="subtitle2">
                    {props.history.optionLicenses &&
                      props.history.optionLicenses.map((v, i) => {
                        let len = props.history.optionLicenses.length;
                        if (i + 1 === len) {
                          return v.name;
                        } else {
                          return v.name + ', ';
                        }
                      })}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={1}>
              <Grid item>
                <PersonIcon />
              </Grid>
              <Grid item>
                {props.history._publisher && (
                  <Typography variant="subtitle2">{props.history._publisher.name}</Typography>
                )}
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={1}>
              <Grid item>
                <InfoIcon />
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">{props.history.type}</Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={1}>
              <Grid item>
                <CalendarMonthIcon />
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">
                  {moment(props.history.date).format('MM/DD/YYYY [at] h:mm A')}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </CustomCard>
      </Grid>
    </Grid>
  );
};

export default HistorySubCompoent;
