import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { resetLicense } from '../../../redux/license';
import {
  Button,
  Box,
  Card,
  Container,
  Divider,
  FormControl,
  Grid,
  Typography,
  useMediaQuery,
  TextField,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';
import { styled, createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import LicenseComponent from './LicenseComponent';

import { GROUP_SERVER, License_SERVER } from '../../Config';
import { Parser } from '@json2csv/plainjs';
import { string as stringFormatter } from '@json2csv/formatters';
import { downloadLicenseFile } from '../../../utils/util';
import axios from 'axios';
axios.defaults.withCredentials = true;

const theme = createTheme({
  main: 'rgba(0, 0, 0, 0.6)',
  orange: orange[500],
});

const CustomFormControl = styled(FormControl)({
  height: '72px',
  '& .MuiInputLabel-root': {
    color: theme.main,
  },
  '& label.Mui-focused': {
    color: theme.orange,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.orange,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.orange,
    },
  },
  '& .MuiFormHelperText-root': {
    color: 'red',
    fontSize: '0.9rem',
    fontWeight: '600',
  },
});

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 300 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#FF7D19' : '#308fe8',
  },
}));

const CustomButton = styled(Button)({
  textTransform: 'none',
});

function LicensePage() {
  const matches = useMediaQuery('(max-width:768px)');
  const [licenses, setLicenses] = useState([{ name: 'Licenses 1' }]);
  const [customer, setCustomer] = useState('');
  const [selectSw, setSelectSw] = useState('');
  const [status, setStatus] = useState({});
  const [customerValidation, setCustomerValidation] = useState(false);
  const customerRef = useRef();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const license = useSelector((state) => state.license);

  const downloadAll = () => {
    // console.log(license);
    const opts = {
      data: license.licenses,
      delimiter: '\t',
      formatters: {
        // Never use quotes
        string: stringFormatter({ quote: '' }),
      },
    };
    const parser = new Parser(opts);
    const csv = parser.parse(license.licenses);
    downloadLicenseFile(csv);
  };

  // sw List 선택 시 Progress bar, available count update
  useEffect(() => {
    if (user.userData.isAuth) {
      if (Object.keys(user.userData).length !== 0) {
        if (selectSw !== '' && selectSw !== undefined) {
          // user group 적용 여부 체크
          if (user.userData.group !== undefined) {
            let groupId = user.userData.group._id;

            //sw의 group type 조회 - groupCount name 얻기위함
            axios.get(`${process.env.REACT_APP_SERVER}${License_SERVER}/producttype/${selectSw}`).then((res) => {
              // console.log(res.data[0]);
              let groupType = res.data[0].groupType;
              let swName = res.data[0].name;
              // groupCount 조회
              axios.get(`${process.env.REACT_APP_SERVER}${GROUP_SERVER}/group/${groupId}`).then((res) => {
                // console.log(swName);
                // console.log(res.data[groupType.tCountName]);
                // console.log(res.data[groupType.aCountName]);
                // sw name, total count, available count
                setStatus({
                  swName: swName,
                  tCount: res.data[groupType.tCountName],
                  aCount: res.data[groupType.aCountName],
                });
              });
            });
          }
        }
      }
    }
  }, [selectSw, user]);

  //license page 접근 시 license 발급 리스트 초기화
  useEffect(() => {
    dispatch(resetLicense());
  }, []);

  return (
    <div className="LicensePage">
      {user.userData.group !== undefined && (
        <Container
          maxWidth="lg"
          sx={
            matches ? { paddingTop: '116px', paddingBottom: '120px' } : { paddingTop: '188px', paddingBottom: '120px' }
          }
        >
          <Card elevation={1} sx={{ padding: '16px' }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={3} md={3}>
                <Typography
                  variant="h4"
                  fontWeight={500}
                  sx={{
                    textAlign: 'left',
                    float: 'left',
                    color: 'rgba(197,197,197,1.00)',
                  }}
                >
                  Member
                </Typography>
              </Grid>
              <Divider orientation="vertical" flexItem sx={{ marginLeft: '-1px' }} />
              <Grid item xs={12} sm={9} md={9}>
                <CustomFormControl fullWidth variant="standard">
                  <TextField
                    id="customer"
                    label="Customer"
                    variant="standard"
                    inputProps={{ maxLength: 135 }}
                    inputRef={customerRef}
                    value={customer}
                    onChange={(e) => {
                      setCustomer(e.target.value);
                      setCustomerValidation(false);
                    }}
                    helperText={
                      customerValidation &&
                      "Only letters, numbers and punctuataions (`~!@#$%^&*()-_=+?;:.,<>?') are allowed."
                    }
                  />
                </CustomFormControl>
                <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: '16px' }}>
                  <BorderLinearProgress
                    variant="determinate"
                    value={(status.aCount / status.tCount) * 100}
                    sx={{
                      width: '100%',
                      height: '26px',
                      borderRadius: 1,
                      colorPrimary: {
                        background: 'green',
                      },
                    }}
                  />
                  <Typography position="absolute" fontWeight={600} color={'white'}>
                    {`${status.aCount === undefined ? 0 : status.aCount} / ${
                      status.tCount === undefined ? 0 : status.tCount
                    }`}
                  </Typography>
                </Box>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    float: 'right',
                    marginTop: '22px',
                  }}
                >
                  <Typography fontSize={22} fontWeight={600} color={'blue'} marginRight={2}>
                    {status.swName}
                  </Typography>
                  <Typography fontSize={16} fontWeight={600} marginRight={2}>
                    Available License
                  </Typography>
                  <Typography fontSize={18} fontWeight={600} color={'red'} marginRight={2}>
                    {status.aCount}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Card>
          {licenses &&
            licenses.map((row) => (
              <LicenseComponent
                key={row.name}
                name={row.name}
                customer={customer}
                setSelectSw={setSelectSw}
                customerValidation={setCustomerValidation}
                customerRef={customerRef}
              />
            ))}
          <div style={{ height: '52px' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                float: 'right',
                marginTop: '8px',
              }}
            >
              <CustomButton
                variant="outlined"
                startIcon={<DownloadIcon />}
                sx={{ marginRight: '8px' }}
                onClick={downloadAll}
              >
                Download All
              </CustomButton>
              <CustomButton
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => {
                  let listLen = licenses.length + 1;

                  setLicenses([...licenses, { name: 'Licenses ' + listLen }]);
                }}
              >
                Additional Licenses
              </CustomButton>
            </div>
          </div>
        </Container>
      )}
    </div>
  );
}

export default LicensePage;
