import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Modal,
  NativeSelect,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  // useMediaQuery,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { styled, createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import HistorySubCompoent from './HistorySubCompoent';
import HistorySubSubComponent from './HistorySubSubComponent';
import { resetHistory } from '../../../redux/history';
import { License_SERVER, HISTORY_SERVER } from '../../../components/Config';
import { useForm } from 'react-hook-form';
import axios from 'axios';
axios.defaults.withCredentials = true;

const CustomGrid = styled(Grid)({
  minHeight: '64px',
});

const theme = createTheme({
  main: 'rgba(0, 0, 0, 0.6)',
  orange: orange[500],
});

const customerErrorModalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const CustomFormControl = styled(FormControl)({
  '& .MuiInputLabel-root': {
    color: theme.main,
  },
  '& label.Mui-focused': {
    color: theme.orange,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.orange,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.orange,
    },
  },
});

const CustomRadio = styled(Radio)({
  width: '32px',
  height: '32px',
  marginLeft: '12px',
});

function HistoryPage() {
  const dispatch = useDispatch();
  const [swList, setSwList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [historyList, setHistoryList] = useState();
  const [errorMsgModalOpen, setErrorMsgModalOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const user = useSelector((state) => state.user);

  const handleErrorMsgModalClose = () => {
    setErrorMsg('');
    setErrorMsgModalOpen(false);
  };

  const {
    handleSubmit,
    register,
    setValue,
    // formState: { errors },
  } = useForm({
    defaultValues: {
      agency: 'None',
      customer: '',
      sw: 'None',
      type: 'None',
      date: 'All',
    },
    // resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data, e) => {
    // async request which may result error
    try {
      // console.log(data);
      let specialRule = /([`~!@#$%^&*()-_=+?;:.,<>?'\s])/gi;
      let wordRegex = /^[\p{L}0-9]+$/u;
      let copyCustomer = data.customer;
      let replaceCustomer = copyCustomer.replace(specialRule, '');
      if (replaceCustomer === '' ? false : !wordRegex.test(replaceCustomer)) {
        setErrorMsg(`Only letters, numbers and punctuataions (\`~!@#$%^&*()-_=+?;:.,<>?') are allowed.`);
        setErrorMsgModalOpen(true);
      }
      // 조회 조건 param으로 request 요청
      else {
        await axios.post(`${process.env.REACT_APP_SERVER}${HISTORY_SERVER}/history`, data).then((res) => {
          // console.log(res);
          setHistoryList(res.data);
          dispatch(resetHistory());
        });
      }
    } catch (e) {
      // handle your error
    }
  };

  useEffect(() => {
    //sw List DB로 부터 가져오기
    if (user.userData.hasOwnProperty('isAuth')) {
      // user group 적용 여부 체크
      if (user.userData.group !== undefined) {
        axios
          .get(`${process.env.REACT_APP_SERVER}${HISTORY_SERVER}/groups`)
          .then((res) => {
            // console.log(res.data);
            setGroupList(res.data);
            return res;
          })
          .then((res) => {
            if (user.userData.role === 'user') {
              setValue('agency', res.data[0]._id);
            }
          });

        axios.get(`${process.env.REACT_APP_SERVER}${License_SERVER}/products`).then((res) => {
          setSwList(res.data);
        });
      }
    }
  }, []);

  return (
    <div className="HistoryPage">
      {user.userData.group !== undefined && (
        <Grid container sx={{ height: '100%' }}>
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              height: '100%',
              padding: '16px ',
              backgroundColor: 'white',
            }}
          >
            <Grid item container direction="row" justifyContent="center" alignItems="center">
              <CustomGrid item xs={12}>
                <Typography variant="h3" fontSize={24} fontWeight={600} margin={1}>
                  Search History
                </Typography>
              </CustomGrid>
              <CustomGrid item xs={12}>
                <CustomFormControl type="text" variant="standard" fullWidth sx={{ height: '40px' }}>
                  <InputLabel shrink htmlFor="Agency">
                    Agency
                  </InputLabel>
                  <NativeSelect id="Agency" fullWidth size="small" {...register('agency')} defaultValue="None">
                    {user.userData.role !== 'user' && <option value={'None'}>Select a Agency...</option>}
                    {groupList &&
                      groupList.map((v, i) => {
                        return (
                          <option key={v._id} value={v._id}>
                            {v.name}
                          </option>
                        );
                      })}
                  </NativeSelect>
                </CustomFormControl>
              </CustomGrid>
              <CustomGrid item xs={12}>
                <CustomFormControl fullWidth variant="standard">
                  <TextField
                    fullWidth
                    variant="standard"
                    label="Customer"
                    autoComplete="customer"
                    inputProps={{ maxLength: 135 }}
                    {...register('customer')}
                    size="small"
                    sx={{ color: 'red' }}
                  />
                </CustomFormControl>
              </CustomGrid>

              <CustomGrid item xs={12}>
                <CustomFormControl theme={theme} variant="standard" size="small" fullWidth>
                  <InputLabel shrink htmlFor="sw">
                    S/W
                  </InputLabel>
                  {swList && (
                    <NativeSelect id="sw" label="S/W" fullWidth {...register('sw')}>
                      <option value={'None'}>Select a Software...</option>
                      {swList &&
                        swList.map((v, i) => {
                          return (
                            <option key={v._id} value={v._id}>
                              {v.productname}
                            </option>
                          );
                        })}
                    </NativeSelect>
                  )}
                </CustomFormControl>
              </CustomGrid>
              <CustomGrid item xs={12}>
                <CustomFormControl variant="standard" size="small" fullWidth>
                  <InputLabel shrink htmlFor="type">
                    Type
                  </InputLabel>
                  <NativeSelect id="type" label="Type" fullWidth {...register('type')}>
                    <option value={'None'}>Select a Type...</option>
                    <option value={'New'}>New</option>
                    <option value={'ReIssued'}>ReIssued</option>
                    <option value={'Waiting for Approval'}>Waiting for Approval</option>
                  </NativeSelect>
                </CustomFormControl>
              </CustomGrid>
              <CustomGrid item xs={12}>
                <CustomFormControl>
                  <FormLabel id="date" sx={{ fontWeight: '600' }}>
                    Search By Date
                  </FormLabel>
                  <RadioGroup id="date" name="radio-buttons-group" defaultValue="All">
                    <FormControlLabel
                      value="All"
                      control={<CustomRadio size="small" />}
                      label="All"
                      {...register('date')}
                    />
                    <FormControlLabel
                      value="Day"
                      control={<CustomRadio size="small" />}
                      label="Day"
                      {...register('date')}
                    />
                    <FormControlLabel
                      value="Week"
                      control={<CustomRadio size="small" />}
                      label="Week"
                      {...register('date')}
                    />
                    <FormControlLabel
                      value="Month"
                      control={<CustomRadio size="small" />}
                      label="Month"
                      {...register('date')}
                    />
                  </RadioGroup>
                </CustomFormControl>
              </CustomGrid>

              <Grid item xs={12} sx={{ textAlign: 'center', height: '100%' }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="error"
                  startIcon={<SearchIcon />}
                  onClick={handleSubmit(onSubmit)}
                  sx={{ width: '80%', textTransform: 'none' }}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              height: '100%',
              padding: '8px',
              display: 'inline-flex',
              flexDirection: 'column',
              overflow: 'hidden',
              overflowY: 'scroll',
            }}
            container
          >
            <Grid item xs={12}>
              {historyList &&
                historyList.map((v, i) => {
                  return (
                    <HistorySubCompoent
                      key={i}
                      history={v}
                      idx={i}
                      // selectedHistory={selectedHistory}
                      // setSelectedHistory={setSelectedHistory}
                    />
                  );
                })}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            sx={{
              height: '100%',
              padding: '8px',
              display: 'inline-flex',
              flexDirection: 'column',
              overflow: 'hidden',
              overflowY: 'scroll',
            }}
          >
            {/* {history && <HistorySubSubComponent history={selectedHistory !== '' ? historyList[selectedHistory] : ''} />} */}
            <HistorySubSubComponent />
          </Grid>
        </Grid>
      )}
      <Modal
        open={errorMsgModalOpen}
        onClose={handleErrorMsgModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={customerErrorModalstyle}>
          <ErrorOutlineRoundedIcon color="warning" style={{ fontSize: 64 }} />
          <Typography id="modal-title" style={{ margin: 16, fontWeight: 600, fontSize: '24px' }}>
            {errorMsg}
          </Typography>
          <Button variant="contained" color="error" size="small" onClick={handleErrorMsgModalClose}>
            OK
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default HistoryPage;
