import { createSlice } from '@reduxjs/toolkit';

const initAlert = { alertType: '', alertMsg: '' };
export const alertSlice = createSlice({
  name: 'alert',
  initialState: initAlert,
  reducers: {
    setAlert: (state, action) => {
      state.alertType = action.payload.alertType;
      state.alertMsg = action.payload.alertMsg;
    },
    resetAlert: (state, action) => {
      state.alertType = '';
      state.alertMsg = '';
    },
  },
});

// export const { logoutUser } = userSlice.actions;
export default alertSlice.reducer;
export const { setAlert, resetAlert } = alertSlice.actions;
