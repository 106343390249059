import { createSlice } from '@reduxjs/toolkit';

const initHistory = { historyData: {} };
export const historySlice = createSlice({
  name: 'history',
  initialState: initHistory,
  reducers: {
    setHistory: (state, action) => {
      state.idx = action.payload.idx;
      state.historyData = action.payload.historyData;
    },
    resetHistory: (state, action) => {
      state.historyData = {};
    },
  },
});

// export const { logoutUser } = userSlice.actions;
export default historySlice.reducer;
export const { setHistory, resetHistory } = historySlice.actions;
