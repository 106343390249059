import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Modal,
  Typography,
  TextField,
  Icon,
} from '@mui/material';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import ErrorIcon from '@mui/icons-material/Error';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { enqueueSnackbar } from 'notistack';

import axios from 'axios';
import { GROUP_SERVER } from '../../Config';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'rgb(253,237,237)',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 460,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const GroupLicenseModal = (props) => {
  const [changeLicenseValue, setChangeLicenseValue] = useState('');
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [errorString, setErrorString] = useState('');
  const [emptyError, setEmptyError] = useState(false);
  const [updateConfirmModal, setUpdateConfirmModal] = useState(false);

  const handleClose = () => setOpenConfirmModal(false);
  const handleUpdateConfirmModalClose = () => {
    setUpdateConfirmModal(false);
  };

  const chageValue = (value) => {
    if (value === 0) {
      setChangeLicenseValue('');
    } else if (value < 0) {
      setChangeLicenseValue(0);
    } else if (value > 99999999) {
      setChangeLicenseValue(99999999);
    } else {
      setChangeLicenseValue(value);
    }
  };

  //change group
  const changeGroup = () => {
    let formData = {
      changeType: props.licenseType,
      value: changeLicenseValue,
      groupId: props.groupList[props.selectedIndex]._id,
      groupType: props.groupType,
    };

    if (changeLicenseValue !== '' && changeLicenseValue !== undefined) {
      axios.put(`${process.env.REACT_APP_SERVER}${GROUP_SERVER}/grouplicense`, { formData }).then((res) => {
        if (res.data.err) {
          setErrorString(res.data.msg);
          setOpenConfirmModal(true);
        } else {
          setUpdateConfirmModal(true);
          // enqueueSnackbar('license count changed', {
          //   variant: 'success',
          //   autoHideDuration: 2500,
          // });
          props.onClose(false);
          props.getGroupList();
          setChangeLicenseValue('');
          setEmptyError(false);
        }
      });
    } else {
      setEmptyError(true);
      // enqueueSnackbar('You need to write something!', {
      //   variant: 'warning',
      //   autoHideDuration: 2500,
      // });
    }
  };

  const licenseRemoveConfirm = () => {
    let formData = {
      changeType: props.licenseType,
      value: changeLicenseValue,
      groupId: props.groupList[props.selectedIndex]._id,
      groupType: props.groupType,
      removeConfirm: true,
    };

    if (changeLicenseValue !== '' && changeLicenseValue !== undefined) {
      axios.put(`${process.env.REACT_APP_SERVER}${GROUP_SERVER}/grouplicense`, { formData }).then((res) => {
        if (res.data.err) {
        } else {
          enqueueSnackbar('license count changed', {
            variant: 'success',
            autoHideDuration: 2500,
          });
          setOpenConfirmModal(false);
          props.onClose(false);
          props.getGroupList();
        }
      });
    }
    setChangeLicenseValue('');
  };

  return (
    <>
      <Dialog
        open={props.open}
        aria-hidden={props.open ? 'false' : 'true'}
        onClose={() => {
          setChangeLicenseValue('');
          setEmptyError(false);
          props.onClose();
        }}
        maxWidth={'sm'}
      >
        <DialogTitle variant="h5" align="center">
          {props.licenseType === 'inc' && 'Add License'}
          {props.licenseType === 'dec' && 'Remove License'}
        </DialogTitle>
        <DialogContent align="center" style={{ minWidth: '300px' }}>
          <Typography variant="subtitle1">
            Enter the number of licenses you want to {props.licenseType === 'inc' && 'add'}
            {props.licenseType === 'dec' && 'remove'}
          </Typography>
          <TextField
            fullWidth
            size="small"
            type="number"
            value={changeLicenseValue}
            onChange={(e) => {
              chageValue(Number(e.target.value));
            }}
            InputProps={{
              endAdornment: <Icon>{emptyError && <CloseRoundedIcon color="error" />}</Icon>,
            }}
          />
          <div style={{ height: '40px', marginTop: '16px' }}>
            {emptyError && (
              <div
                style={{
                  backgroundColor: 'rgba(220,220,220,1.00)',
                  height: '40px',
                  marginLeft: '-20px',
                  marginRight: '-20px',
                  alignContent: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <ErrorIcon color="error" />
                <span style={{ verticalAlign: '25%', fontSize: '16px' }}>You need to write something!</span>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="outlined"
            color="inherit"
            onClick={() => {
              setChangeLicenseValue('');
              setEmptyError(false);
              props.onClose();
            }}
            sx={{ textTransform: 'none' }}
          >
            No
          </Button>
          <Button
            size="small"
            variant="contained"
            color="error"
            onClick={() => {
              changeGroup(changeLicenseValue);
            }}
            sx={{ textTransform: 'none' }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={openConfirmModal}
        onClose={handleClose}
        aria-labelledby="modal-group-license-confirm"
        aria-describedby="modal-group-license-confirm-description"
      >
        <Box sx={style}>
          <ErrorOutlineRoundedIcon color="warning" style={{ fontSize: 64 }} />

          <Typography id="modal-group-license-confirm-description" sx={{ mt: 2, pb: 2, whiteSpace: 'pre-wrap' }}>
            {errorString}
          </Typography>
          <Button variant="outlined" color="primary" size="small" onClick={handleClose}>
            No
          </Button>
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={() => {
              licenseRemoveConfirm();
            }}
          >
            Yes
          </Button>
        </Box>
      </Modal>

      <Modal
        open={updateConfirmModal}
        onClose={handleUpdateConfirmModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <CheckCircleOutlineRoundedIcon color="success" style={{ fontSize: 64 }} />
          <Typography id="modal-modal-title" style={{ margin: 16, fontWeight: 600, fontSize: '28px' }}>
            Update Successful
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, pb: 2 }}>
            {'License count changed.'}
          </Typography>
          <Button variant="contained" color="error" size="small" onClick={handleUpdateConfirmModalClose}>
            OK
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default GroupLicenseModal;
